.slider-o-cb-thumb{
    margin-bottom:40px;
    @media(max-width:767px){
        background:#f9f9f9;
        padding-top:40px;
        
    }   


 .sliderthumb {
    padding-left:0px ;
    padding-right:0px;

    .slider-item-main{
        position:relative;
    }

    .img-cb{
        width:100% !important;
        height:auto !important;
        max-height:initial !important;
    }
    .slider-img{
        position:relative;
        display:flex;
        width:100%;

        span{
           width:100% !important;
           height:auto !important; 
        }
    }

    .slider-inner-img{
        position:relative;
        width:100%;
        display:flex;
        z-index: 1;
        &:after{
            content: "";
            background: rgba(0, 0, 0, 0.4);
            width: 100%;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0px;
        }
    }


    .content-outer-cb{
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        z-index:2;
    }

    .content-inner-cb{
        color:var(--white);
        padding-bottom: 170px;
        width: 100%;
        .h-title{
            color:var(--white)
        }
    }

    @media(max-width:991px){
        .content-inner-cb{
            padding-bottom:80px;
        }
    }
 
    @media(max-width:767px){

        .slider-img{
        display:block;
    }

        .content-outer-cb{
        position: relative;
        display: block;
    }

    .content-inner-cb{
        color:var(--bs-text-color);
        padding-bottom: 15px;
        text-align:center;
        .h-title{
            color:var(--bs-text-color)
        }
    }
       
    }

}

 .thumb-o-cb{
    margin-top: -100px;
    position:relative;

    .thumb-img-cb{
        position:relative;
    display: inline-flex !important;
    justify-content: flex-end;
    min-height: 180px;
    align-items: center;
    cursor: pointer;
    }

    .thumb-inner-cb{
        position:relative;
        display: inline-flex;
        border-radius: 50%;
        overflow: hidden;
        border: 6px solid #ccc;
        width:100px;
        height:100px;
        flex: 0 0 auto;
    }

    @media(max-width:991px){
         margin-top: -70px;

         .thumb-img-cb{
            min-height: 120px;
    }

        .thumb-inner-cb{
                position:relative;
                display: inline-flex;
                border-radius: 50%;
                overflow: hidden;
                border: 3px solid #ccc;
                width:60px;
                height:60px;
                margin-right: 5px;
        }
    }

    @media(max-width:767px){

         margin-top: -50px;
         padding-bottom: 30px;

          .thumb-img-cb{
    min-height: 80px;
    }

        .thumb-inner-cb{
                border: 2px solid #ccc;
                width:40px;
                height:40px;
        }
    }

 }

}
    