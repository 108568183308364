.categorySlider_cb {
    .col_full {
        flex: 1 0 0%;
    }

    .slider-container {
        position: relative;
        padding-left: 30px;
        padding-right: 30px;

        .con_box {
            padding-left: 15px;
            padding-right: 15px;
            position: relative;
            display: flex !important;
            justify-content: center;
        }

        .img-b-container {
            display: flex;
            flex-wrap: wrap;
            position: relative;
        }

        .img-i-container {
            display: flex;
            flex-wrap: wrap;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0px;
                left: 0px;
                background: -moz-linear-gradient(top,
                            rgba(0, 0, 0, 0) 0%,
                            rgba(0, 0, 0, 0.1) 60%,
                            rgba(0, 0, 0, 1) 100%);
                    background: -webkit-linear-gradient(top,
                            rgba(0, 0, 0, 0) 0%,
                            rgba(0, 0, 0, 0.1) 60%,
                            rgba(0, 0, 0, 1) 100%);
                    background: linear-gradient(to bottom,
                            rgba(0, 0, 0, 0) 0%,
                            rgba(0, 0, 0, 0.1) 60%,
                            rgba(0, 0, 0, 1) 100%);
                z-index: 1;
            }
        }

        .slider-content {
            position: absolute;
            bottom: 0px;
            z-index: 2;
            padding: 15px 25px 10px;
            color: var(--white);
            width: 100%;
            left: 0px;
            font-size: 0.875rem;

            p {
                font-size: 0.875rem;
            }

            .white {
                color: var(--white);
                margin-bottom: 0px;

                * {
                    color: var(--white);
                }
            }
        }
    }
}