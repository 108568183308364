.footer_outer_cb {
    position: relative;
    color:var(--white);
    
    .list-none{
        margin: 0;
        padding: 0;
    }

    .title{
        color:var(--white);
        font-family: var(--bs-font-title-family);
        font-size: 1.625rem;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 5px;
    }

    .f-link-list{
        padding-bottom:15px;
    }

    .f-link-text{
        margin-bottom:15px;
        li{
            position: relative;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        a{
            color:var(--white);
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;

            &:hover{
                opacity: 0.5;
            }
        }
    }

    .container_col {
        flex: 0 0 auto;
        width: 25%;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
    }

    .social_cb{
        display:flex;
        
        li{
            position:relative;
            margin-right: 20px;
            &:last-child{
                margin-right: 0;
            }
        }
        a{
            display:flex;
            align-items:center;
            justify-content:center;
            width:40px;
            height:40px;
            background: var(--white);
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            transition: all .3s ease-in-out;
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -ms-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            &:hover{
                opacity:0.5
            }
        }
    }

    .container_col_12 {
        flex: 0 0 auto;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
    }

    .f-copyright{
       border-top:1px solid rgba(255, 255, 255, .2);
       padding-top:20px;
       padding-bottom:20px;
       display:flex;
       justify-content:space-between;
       font-size: 0.875rem;

       ul{
            margin:0px;
            padding:0px;
            display:flex;
            flex-wrap:wrap;
            order:1;

            li {
                position:relative;
                padding-left:15px;
                padding-right:15px;

                &:after{
                    content:"";
                    position:absolute;
                    top:50%;
                    right:0px;
                    width:1px;
                    height:10px;
                    background: var(--white);
                    margin-top:-5px;
                }

                &:last-child{
                    padding-right:0px;
                    &:after{
                        display:none
                    }
                }
            }

            a {
                color:var(--white);
                transition: all 0.3s ease-in-out;
                -webkit-transition: all 0.3s ease-in-out;
                -moz-transition: all 0.3s ease-in-out;
                -ms-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                &:hover{
                    opacity: 0.5;
                }
            }
        }

       p {
        order: 0;
        margin:0px
       }
    }

    @media(max-width:991px){
         .container_col {
            width: 50%;
        }
    }

    @media(max-width:767px){
         .container_col {
            width: 100%;
        }

        .f-map{
            display: none;
        }

        .social_cb {
            margin-bottom: 15px;
        }

        .f-copyright{
            flex-direction: column;
            ul {
                order: 0;
                margin-bottom:10px;
                li{
                    &:first-child{
                        padding-left:0px;
                    }
                }
            }
        }
    }
}