.faqs-page-container {
  

  .content-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: rgba(0, 0, 0, 0.85);
    color: #fff;

    padding: 100px 30px 10px;
    overflow: hidden;
  }

  .heading {
    font-size: 3.5rem;
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .filter-container {
    margin-bottom: 30px;

    :global(.custom-select) {
      margin-bottom: 10px;
    }
  }

  .faqs-container {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .faq-collapse-container {
    width: 100%;
    margin-bottom: 10px;

    .collapse {
      color: #fff;
      background-color: transparent;
      border: 1px solid #fff;

      .faq-panel {
        border-bottom: none;

        :global(.ant-collapse-content) {
          background-color: transparent;
          border-top: none;
        }

        :global(.ant-collapse-content-box),
        :global(.ant-collapse-header) {
          color: #fff;
        }

        :global(.ant-collapse-header) {
          font-weight: 700;
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .heading {
      font-size: 1.875rem;
    }
  }

  @media only screen and (min-width: 576px) {
    .heading {
      font-size: 1.875rem;
    }
  }

  @media only screen and (min-width: 768px) {
    .heading {
      font-size: 2.25rem;
    }
  }

  @media only screen and (min-width: 992px) {
    .heading {
      font-size: 3;
    }
  }

  @media only screen and (min-width: 1200px) {
    .heading {
      font-size: 3.5;
    }
  }
}

.faqs-cb {
  position: relative;

  .col_full {
    flex: 1 0 0%;
  }

  .faq-container-inner {
    position: relative;
    margin-top: 15px;
  }

  .faq-inner {
    border: 1px solid rgba(55, 55, 55, .4);
    position: relative;
    padding: 10px 15px 10px 15px;
    margin-bottom: 10px;

    .heading_title_default {
      h1,h2,h3,h4,h5,h6,p{
      padding-right:15px;
      font-size: 1rem;
      font-weight: 700;
      margin-bottom: 0;
      cursor: pointer;
      user-select: none;
      position: relative;

      /* @media(max-width:767px){
        font-size: 0.813rem;
      } */

      &:after {
        content: "";
        position: absolute;
        top: 7px;
        right: 0px;
        width: 10px;
        height: 10px;
        background: url(../../../public/assets/images/faq-arrow.svg) no-repeat
          center;
        background-size: auto;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
      }
    }
    }

    p {
      font-size: 1rem;
      /* @media(max-width:767px){
        font-size: 0.813rem;
      } */
    }
  }

  .faq-description {
    padding-top: 0px;
    height: 0px;
    opacity: 0;
    margin: 0px;
    transition: all 0.05s ease-in-out;
    -webkit-transition: all 0.05s ease-in-out;
    -moz-transition: all 0.05s ease-in-out;
    -ms-transition: all 0.05s ease-in-out;
    -o-transition: all 0.05s ease-in-out;
}

  .activeFaq {
    .heading_title_default {
       h1,h2,h3,h4,h5,h6,p{
      &:after {
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
      }
    }
    }

    .faq-description {
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      padding-top: 10px;
      height: auto;
      opacity: 1;
      margin: 0px;
    }
  }

  .form-field {
    background: transparent;
    width: 100%;
    border: 1px solid rgba(55, 55, 55, 0.4);
    color: var(--bs-text-color) !important;
    font-size: 1rem;
    padding:5px 25px 5px 10px;
    min-height: 44px;

    &.dd-arrow-cb{
      background-image: url("../../../public/assets/images/select-field-arrow.svg");
      background-position: top 17px right 10px;
      background-repeat: no-repeat;
    }
  
    &.no-right-border{
      border-right:0px;
      width: max-content;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background: var(--bs-text-color) url("../../../public/assets/images/select-field-arrow-white.svg");
      background-position: top 17px right 10px;
      background-repeat: no-repeat;
      color: var(--white) !important;
      option {
      color:var(--white);
    }
    }

    &::placeholder {
      color: var(--bs-text-color);
    }

    &:focus-visible {
      outline: none;
    }
  }

  .form-field-select {
    padding:5px 25px 5px 10px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
     background-position: top 19px right 10px;
    background-repeat: no-repeat;
    width: 100%;
    border: 1px solid  rgba(55, 55, 55, 0.4);
    color: var(--bs-text-color) !important;
    font-size: 1rem;
  
    option {
      color:var(--bs-text-color);
    }

    &.dd-arrow{
      background-image: url("../../../public/assets/images/select-field-arrow.svg");
    }
  }

  .faq-main-cb{
    justify-content:space-between;
    .faq-box-l-cb, .faq-box-r-cb{
      max-width: 300px;
      margin-bottom:25px;

      @media(max-width:767px){
        max-width:100%;
      }
    }
  }
  
}