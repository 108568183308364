.blog-block {
  .slider-container {
    padding-bottom: 30px;
  }

  .link-text {
    color: var(--bs-text-color);
    font-weight: 600;
    text-decoration: underline;
    margin-bottom: 15px;

    @media(max-width:767px) {
      font-size: 0.875rem;
    }
  }

  .con_auto {
    flex: 0 0 auto;
    width: auto;
  }

  .con_auto_50 {
    flex: 0 0 auto;
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .con_auto_100 {
    flex: 0 0 auto;
    width: 100%;
  }

  .setWidth {
    max-width: 320px;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .col_full {
    width: calc(100% - 350px);
  }

  .img-b-container {
    position: relative;
  }

  .img-i-container {
    position: relative;
    display: flex;

    &:after {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background: -moz-linear-gradient(top,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.1) 60%,
          rgba(0, 0, 0, 1) 100%);
      background: -webkit-linear-gradient(top,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.1) 60%,
          rgba(0, 0, 0, 1) 100%);
      background: linear-gradient(to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.1) 60%,
          rgba(0, 0, 0, 1) 100%);
      z-index: 1;
    }
  }

  .blog-content {
    color: var(--white);
    font-size: 1rem;
    position: absolute;
    z-index: 2;
    padding: 15px;
    bottom: 0px;
    width: 100%;
    text-align: left;

    span {
      opacity: 0.8;
      color: var(--white);
      display: block;
      margin-bottom: 5px;

      p {
        opacity: 0.8;
        color: var(--white);
        display: block;
        margin-bottom: 5px;
      }
    }

  

    p,
    a {
      font-weight: 600;
      color: var(--white);
      /* white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; */
    }
  }

  @media (max-width: 991px) {
    .img-i-container {
      span {
        width: 100% !important;
      }
    }
  }

  @media (max-width: 767px) {
    .con_auto_50 {
      text-align: center;
    }

    .con_auto {
      width: 100%;
    }

    .setWidth {
      max-width: 100%;
      height: auto;
      text-align: center;
    }

    .col_full {
      width: 100%;
    }

    .img-b-container {
      position: relative;
      text-align: center;
      display: inline-block;
    }

    .img-i-container {
      display: inline-flex;
    }

    .img-i-container {
      span {
        width: initial !important;
      }
    }

    .blog-content {
      font-size: 0.875rem;

      p {
        font-size: 1rem;
      }
    }
  }
}