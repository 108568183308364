.mc_ps_cb {
  position: fixed;
  top: 0px;
  left: 0px;
  padding-top: 25px;
  padding-bottom: 18px;
  z-index: 91;
  background: var(--black);

  * {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }

  &.sticky-header {
    padding-top: 10px;
    padding-bottom: 10px;

    .blink-logo {
      height: 38px;
    }

    /* .link_menu_cb li {
      padding-left: 13px;
      padding-right: 13px;
    } */
  }
}

.h_left_section {
  position: relative;
  padding-left: 15px;
  padding-right: 0px;
}

.blink-logo {
  display: block;
  width: auto;
  height: 44px;
}

.logo_cb {
  display: block;
  //   width: 200px;
  //   height: 44px;
  margin-bottom: 10px;
  //   text-indent: -99999px;
  //   background-repeat: no-repeat;
  //   //   background-image: url(../../../public/assets/images/new_logo.gif);
  //   /* animation: blink 4s infinite;
  //      -webkit-animation: blink 4s infinite; */
  //   background-size: contain !important;
}

.logo_container {
  text-align: center;
  margin-bottom: 15px;

  span {
    display: block;
    width: 220px;
    height: 55px;
    margin-bottom: 10px;
    text-indent: -99999px;
    background-repeat: no-repeat;
    animation: blink 4s infinite;
    -webkit-animation: blink 4s infinite;
  }
}

.logo_img {
  max-width: 100%;
}

.location_cb {
  position: relative;

  p {
    padding: 0;
    margin: 0;
    position: relative;
    color: #fff;
    font-size: 0.75rem;
    padding-left: 16px;
    cursor: pointer;
  }

  &:before {
    content: "";
    position: absolute;
    background: url(../../../public/assets/images/location-icon.svg) no-repeat;
    background-size: contain;
    width: 12px;
    height: 16px;
    left: 0;
  }
}

.h_right_section {
  position: relative;
  padding-left: 5px;
  padding-right: 15px;
}

.hl_cb {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
}

.login-bth {
  display: inline-flex;
  max-width: 120px;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 12px 25px;
  border: 1px solid #fff;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  margin-left: 15px;

  &:hover {
    color: #fff;
    opacity: 0.5;
  }
}

.h_hm_menu {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.framer-1 {
  background-color: rgb(255, 255, 255);
  border-radius: 6.66667% / 50%;
  transform: none;
  transform-origin: 50% 50% 0px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.framer-2 {
  background-color: rgb(255, 255, 255);
  border-radius: 1px;
  transform: none;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.framer-3 {
  background-color: rgb(255, 255, 255);
  border-radius: 5% / 50%;
  transform: none;
  transform-origin: 50% 50% 0px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.framer-1 {
  position: absolute;
  overflow: visible;
  width: 15px;
  height: 2px;
  left: 3px;
  top: 6px;
  flex: 0 0 auto;
}

.framer-2 {
  position: absolute;
  overflow: visible;
  width: 24px;
  height: 2px;
  left: calc(50% - 12px);
  top: calc(50% - 1px);
  flex: 0 0 auto;
}

.framer-3 {
  position: absolute;
  overflow: visible;
  width: 20px;
  height: 2px;
  bottom: 6px;
  left: 3px;
  flex: 0 0 auto;
}

.activeMenu .framer-1 {
  background-color: rgb(255, 255, 255);
  border-radius: 8.33333% / 50%;
  transform: translate3d(0px, 0px, 0px) rotate(45deg) scale(1, 1);
  transform-origin: 50% 50% 0px;
}

.activeMenu .framer-2 {
  background-color: rgb(255, 255, 255);
  border-radius: 1px;
  transform: rotate(-45deg) translateZ(0px);
  opacity: 1;
}

.activeMenu .framer-3 {
  background-color: rgb(255, 255, 255);
  border-radius: 7.14286% / 50%;
  transform: translate3d(0px, 0px, 0px) rotate(45deg) scale(1, 1);
  transform-origin: 50% 50% 0px;
}

.activeMenu .framer-1 {
  width: 12px;
  height: 2px;
  inset: 10px auto auto 5px;
  flex: 0 0 auto;
}

.activeMenu .framer-3 {
  width: 14px;
  height: 2px;
  inset: auto 4px 10px auto;
  flex: 0 0 auto;
}

.mc_sb_overlay {
  position: fixed;
  right: -200%;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.mc_sb_overlay.activeNavbar {
  right: 0px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.mc_sb_m_cb {
  background: #000000;
  max-width: 450px;
  width: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #d8d8d8 rgba(216, 216, 216, 0.4);
  scrollbar-width: thin;

  &::-webkit-scrollbar-thumb {
    background: #d8d8d8;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: rgba(216, 216, 216, 0.4);
  }

  .loc_close {
    position: absolute;
    width: 30px;
    height: 30px;
    background: url(../../../public/assets/images/close-icon.svg) no-repeat center;
    background-size: 70%;
    display: block;
    text-indent: -99999px;
    top: 20px;
    right: 20px;
    cursor: pointer;
    margin-right: 10px;
    z-index: 3;
  }
}

.mc_m_cb {
  position: relative;
  padding: 50px 15px 70px;
  width: 100%;
}

.mc_navigation {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-bottom: 25px;
}

.mc_navigation li {
  display: block;
  text-align: left;
  margin-bottom: 10px;
  font-size: 1rem;
  padding-left: 20px;
}

.mc_navigation li a {
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.mc_navigation li a:hover {
  opacity: 0.5;
}

.mc_query {
  font-size: 1.2rem;
  position: relative;
  text-align: center;
}

.mc_query p {
  margin-bottom: 0px;
  font-size: 1rem;
}

.mc_query a {
  color: #fff;
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 800;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  margin-bottom: 15px;
  display: block;
}

.mc_query a:hover {
  opacity: 0.5;
}

.mc_social_link {
  margin-top: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.mc_social_link ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
}

.mc_social_link ul li {
  width: 30px;
  height: 30px;
  margin-right: 3px;
}

.mc_social_link {

  .linkedin_cb,
  .fb_cb,
  .insta_cb,
  .twitter_cb {
    text-indent: -99999px;
    display: block;
  }

  .fb_cb {
    background: url(../../../public/assets/images/facebook.svg) no-repeat center;
  }

  .insta_cb {
    background: url(../../../public/assets/images/instagram.svg) no-repeat center;
  }

  .twitter_cb {
    background: url(../../../public/assets/images/twitter.svg) no-repeat center;
  }

  .linkedin_cb {
    background: url(../../../public/assets/images/linkedin.svg) no-repeat center;
  }
}

.mc_social_link p {
  margin: 0;
  padding: 0;
  margin-right: 10px;
  line-height: 1;
  margin-top: 6px;
}

.mc_social_link ul a {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.mc_social_link ul a:hover {
  opacity: 0.5;
}

.blk_c_t_cb {
  .location_cb {
    position: relative;

    p {
      color: #000;
    }

    &:before {
      background: url(../../../public/assets/images/location-icon-black.svg) no-repeat;
    }
  }

  .hl_cb {
    color: #000;
  }

  .login-bth {
    color: #000;
    border: 1px solid #000;

    &:hover {
      color: #000;
      border: 1px solid rgba(0, 0, 0, 0.5);
    }
  }

  .framer-1,
  .framer-2,
  .framer-3,
  .activeMenu .framer-1,
  .activeMenu .framer-2,
  .activeMenu .framer-3 {
    background-color: rgb(0, 0, 0);
  }

  .mc_sb_overlay {
    background-color: rgba(255, 255, 255, 0.7);
  }

  .mc_sb_m_cb {
    background: #fff;
  }

  .mc_navigation li a,
  .mc_query a {
    color: #000;
  }

  .mc_social_link {
    .fb_cb {
      background: url(../../../public/assets/images/facebook-black.svg) no-repeat center;
    }

    .insta_cb {
      background: url(../../../public/assets/images/instagram-black.svg) no-repeat center;
    }

    .twitter_cb {
      background: url(../../../public/assets/images/twitter-black.svg) no-repeat center;
    }

    .linkedin_cb {
      background: url(../../../public/assets/images/linkedin.svg) no-repeat center;
    }
  }

  .logo_cb {
    background: url(../../../public/assets/images/logo-black.svg) no-repeat;
  }
}

.mail_cb a {
  font-size: 1rem;
  font-weight: 200;
}

.link_menu_cb {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  li {
    padding: 5px 15px;
  }

  a,
  span {
    color: #fff;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
}

.link_menu_cb {
  &.v_mob_cb {
    display: none;
  }

  li {
    .snake-border {
      padding: 5px 15px;

      a {
        background: linear-gradient(to right, #cb0009, #720005);
        padding: 5px 10px;
        overflow: hidden;

        position: absolute;
        top: -2px;

        span:nth-child(1) {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: linear-gradient(to right, #bdc3c7, #fff);
          animation: animate1 2s linear infinite;
        }

        span:nth-child(2) {
          position: absolute;
          top: 0;
          right: 0;
          width: 2px;
          height: 100%;
          background: linear-gradient(to bottom, #bdc3c7, #fff);
          animation: animate2 2s linear infinite;
          animation-delay: 1s;
        }

        span:nth-child(3) {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: linear-gradient(to left, #bdc3c7, #fff);
          animation: animate3 2s linear infinite;
        }

        span:nth-child(4) {
          position: absolute;
          top: 0;
          left: 0;
          width: 2px;
          height: 100%;
          background: linear-gradient(to top, #bdc3c7, #fff);
          animation: animate4 2s linear infinite;
          animation-delay: 1s;
        }
      }

      .placeholder {
        opacity: 0;
      }
    }
  }
}

@keyframes animate1 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes animate2 {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(100%);
  }
}

@keyframes animate3 {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes animate4 {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(-100%);
  }
}

.mc_navigation {
  margin-top: 40px;

  li {
    margin-bottom: 20px;

    span {
      cursor: pointer;
    }
  }
}

.mc_m_cb {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bot_c_a_cb {
  margin-top: auto;
}

@media (min-width: 1101px) and (max-width: 1400px) {
  .mc_ps_cb {
    padding-top: 15px;
    padding-bottom: 10px;
  }

  .link_menu_cb {
    li {
      padding: 5px 9px;

      a {
        font-size: 0.875rem;
      }
    }
  }

  .link_menu_cb a,
  .link_menu_cb span {
    font-size: 0.875rem;
  }
}

@media (min-width: 1300px) and (max-width: 1699px) {
  .link_menu_cb {
    li {
      padding: 5px 8px;
    }
    a,
    span {
      font-size: 0.813rem;
    }
  }
}

.desktop_active_link {
  display: block;
}

.mob_active_link {
  display: none;
}

@media (max-width: 1200px) {
  .desktop_active_link {
    display: none;
  }

  .mob_active_link {
    display: block;
  }

  .mc_m_cb {
    padding-left: 30px;
    padding-right: 30px;
  }

  .mc_navigation {
    margin-bottom: 40px;
    margin-top: 0;

    li {
      padding-left: 0px;
    }
  }

  .link_menu_cb {
    &.d_hide {
      display: none;
    }

    &.v_mob_cb {
      display: block;
      margin-top: 20px;
      padding-bottom: 15px;
      margin-bottom: 30px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);

      li {
        display: block;
        text-align: left;
        margin-bottom: 10px;
        font-size: 1.2rem;
        padding-left: 0px;
        padding-top: 0;

        .snake-border {
          padding: 0;

          a {
            top: unset;
          }
        }
      }

      a {
        font-size: 1rem;
        color: #fff;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
      }
    }
  }
}

@media (max-width: 767px) {
  .mc_ps_cb {
    padding-top: 15px;
  }

  .mc_social_link {
    margin-top: 20px;
  }

  .mc_navigation {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .link_menu_cb {
    &.v_mob_cb {
      margin-bottom: 20px;
      padding-bottom: 10px;

      li {
        margin-bottom: 5px;
      }
    }
  }
}

@media (max-width: 424px) {
  .mc_ps_cb {
    padding-top: 10px;
    padding-bottom: 5px;
  }

  .location_cb:before {
    top: 1px;
  }

  .mc_sb_m_cb .loc_close {
    width: 24px;
    height: 24px;
    margin-right: 0px;
  }

  .link_menu_cb.v_mob_cb {
    margin-top: 0;
  }

  .link_menu_cb.v_mob_cb li {
    padding: 0;

    .snake-border {
      padding: 0;

      a {
        top: unset;
      }
    }
  }

  .mc_navigation li {
    margin-bottom: 5px;
  }

  .link_menu_cb.v_mob_cb {
    margin-bottom: 10px;
  }

  .location_cb p {
    font-size: 12px;
  }

  .mc_query p {
    margin-bottom: 5px;
  }

  .mc_social_link {
    margin-top: 10px;

    p {
      margin-top: 0;
    }
  }

  .mc_m_cb {
    padding-bottom: 25px;
  }

  .mc_navigation {
    padding-bottom: 5px;
  }

  .mc_query {
    font-size: 1rem;
  }

  .mc_query a {
    font-size: 1.5rem;
  }

  .link_menu_cb.v_mob_cb a,
  .mc_navigation li a,
  .mail_cb a,
  .mc_social_link p {
    font-size: 0.875rem !important;
  }
}

@keyframes blink {
  0% {
    background-image: url(../../../public/assets/images/new_logo1.png);
  }

  49% {
    background-image: url(../../../public/assets/images/new_logo1.png);
  }

  51% {
    background-image: url(../../../public/assets/images/new_logo2.png);
  }

  100% {
    background-image: url(../../../public/assets/images/new_logo2.png);
  }
}

@-webkit-keyframes blink {
  0% {
    background-image: url(../../../public/assets/images/new_logo1.png);
  }

  49% {
    background-image: url(../../../public/assets/images/new_logo1.png);
  }

  51% {
    background-image: url(../../../public/assets/images/new_logo2.png);
  }

  100% {
    background-image: url(../../../public/assets/images/new_logo2.png);
  }
}

.btn-book-online {
  color: #fff;
  font-size: 12px;
  display: none;
  padding: 10px 15px;
  background: transparent;
  border: 1px solid #fff;
}

@media (max-width: 767px) {
  .btn-book-online {
    display: block;
  }

  .h_hm_menu {
    margin-left: 10px;
  }
}