$size: 0.25rem;

@mixin sizing($name, $styleName) {
  @for $i from 0 through 3 {
    $mSize: $size * $i + 0.5;

    .#{$name}-#{$i}\.5 {
      #{$styleName}: $mSize;
    }

    .#{$name}t-#{$i}\.5 {
      #{$styleName}-top: $mSize;
    }

    .#{$name}b-#{$i}\.5 {
      #{$styleName}-bottom: $mSize;
    }

    .#{$name}l-#{$i}\.5 {
      #{$styleName}-left: $mSize;
    }

    .#{$name}r-#{$i}\.5 {
      #{$styleName}-right: $mSize;
    }

    .#{$name}x-#{$i}\.5 {
      #{$styleName}-left: $mSize;
      #{$styleName}-right: $mSize;
    }

    .#{$name}y-#{$i}\.5 {
      #{$styleName}-top: $mSize;
      #{$styleName}-bottom: $mSize;
    }

    .#{$name}t-#{$i}\.5 {
      #{$styleName}-top: $mSize;
    }
  }

  @for $i from 1 through 96 {
    $mSize: $size * $i;

    .#{$name}-#{$i} {
      #{$styleName}: $mSize;
    }

    .#{$name}t-#{$i} {
      #{$styleName}-top: $mSize;
    }

    .#{$name}b-#{$i} {
      #{$styleName}-bottom: $mSize;
    }

    .#{$name}l-#{$i} {
      #{$styleName}-left: $mSize;
    }

    .#{$name}r-#{$i} {
      #{$styleName}-right: $mSize;
    }

    .#{$name}x-#{$i} {
      #{$styleName}-left: $mSize;
      #{$styleName}-right: $mSize;
    }

    .#{$name}y-#{$i} {
      #{$styleName}-top: $mSize;
      #{$styleName}-bottom: $mSize;
    }

    .#{$name}t-#{$i} {
      #{$styleName}-top: $mSize;
    }
  }
}

@include sizing("w", "width"); // create all width sizing
@include sizing("h", "height"); // create all height sizing
@include sizing("max-h", "max-height"); // create all max height sizing
