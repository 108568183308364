.testimonials_cb{

    .t_slider{
        position: relative;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .container_col {
        flex: 0 0 auto;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
    }
    
    .t_item{
        display: inline-flex !important;
        flex-wrap:wrap;
        padding-bottom:15px;
    }

    .circle_img{
        display:inline-flex;
        overflow: hidden;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        margin-bottom:20px
    }

    .heading_title_default{
         h1,h2,h3,h4,h5,h6,p{
        font-size: 1.375rem;
        font-weight: 700;
        margin-bottom: 0;
        @media(max-width:767px){
            font-size: 1rem;
        }
        }
    }

    .mLR_atuo{
        margin-left:auto;
        margin-right:auto;
    }

    .pt_15{
        padding-top:15px
    }

    .con_auto {
        flex: 0 0 auto;
        width: auto;
    }

    .col_full {
        flex: 1 0 0%;
    }

    @media(max-width:767px){
        .t_slider{
            padding-top:10px;
        }

        .t_item{
            text-align:center;
        }

        .con_auto {
            width:100%
        }
    }
}