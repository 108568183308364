.slider-block {

.inner-slider-cb{
    position: relative;
    padding-top: 30px;
    padding-bottom:0px;
    padding-left: 30px;
    padding-right: 30px;
}
  .maxWidth {
    max-width: 680px;
    margin-left: auto;
    margin-right: auto;
  }

  

  .col_full_50{
    flex: 0 0 auto;
    width: 50%;
    padding-left:15px;
    padding-right:15px;
  }

  .line-height{
    line-height: 2;
  }
 
  .con_auto {
    flex: 0 0 auto;
    width: auto;
   
  }

  .col_full {
    flex: 1 0 0%;
     display:flex;
     align-items:center;

     p{
        font-size:1rem;
     }
  }

    .link-container {
        color:var(--bs-text-color);
        font-size:1rem;
        padding-left:0px;
    }
  
    .thumb_img {
        display: inline-flex;
        margin-bottom: 20px;
    }
    
     @media(max-width:767px){
         text-align:center;

         .col_full {
    justify-content:center;
  }

        .con_auto, .col_full, .c-text-container {
            width:100%;
           
        }
    }

}
    