$space: 0.25rem;

@mixin spacing($name, $styleName) {
  @for $i from 0 through 3 {
    $mSpacing: $space * $i + 0.5;

    .#{$name}-#{$i}\.5 {
      #{$styleName}: $mSpacing;
    }

    .#{$name}t-#{$i}\.5 {
      #{$styleName}-top: $mSpacing;
    }

    .#{$name}b-#{$i}\.5 {
      #{$styleName}-bottom: $mSpacing;
    }

    .#{$name}l-#{$i}\.5 {
      #{$styleName}-left: $mSpacing;
    }

    .#{$name}r-#{$i}\.5 {
      #{$styleName}-right: $mSpacing;
    }

    .#{$name}x-#{$i}\.5 {
      #{$styleName}-left: $mSpacing;
      #{$styleName}-right: $mSpacing;
    }

    .#{$name}y-#{$i}\.5 {
      #{$styleName}-top: $mSpacing;
      #{$styleName}-bottom: $mSpacing;
    }

    .#{$name}t-#{$i}\.5 {
      #{$styleName}-top: $mSpacing;
    }
  }

  @for $i from 1 through 96 {
    $mSpacing: $space * $i;

    .#{$name}-#{$i} {
      #{$styleName}: $mSpacing;
    }

    .#{$name}t-#{$i} {
      #{$styleName}-top: $mSpacing;
    }

    .#{$name}b-#{$i} {
      #{$styleName}-bottom: $mSpacing;
    }

    .#{$name}l-#{$i} {
      #{$styleName}-left: $mSpacing;
    }

    .#{$name}r-#{$i} {
      #{$styleName}-right: $mSpacing;
    }

    .#{$name}x-#{$i} {
      #{$styleName}-left: $mSpacing;
      #{$styleName}-right: $mSpacing;
    }

    .#{$name}y-#{$i} {
      #{$styleName}-top: $mSpacing;
      #{$styleName}-bottom: $mSpacing;
    }

    .#{$name}t-#{$i} {
      #{$styleName}-top: $mSpacing;
    }
  }
}

@include spacing("m", "margin"); // create all margin spacings
@include spacing("p", "padding"); // create all padding spacings
