.overview_cb {

    &.rightOrder {

        .order-1 {
            order: 1;
        }

        .order-0 {
            order: 0;
        }

        .space_left {
            padding-left: 15px;
            padding-right: 40px;

            @media(max-width:767px) {
                padding-right: 15px;
            }
        }
    }

    .space_left {
        padding-left: 40px
    }

    .mLR_atuo {
        margin-left: auto;
        margin-right: auto;
    }

    .pt_15 {
        padding-top: 15px
    }

    .con_auto {
        flex: 0 0 auto;
        width: auto;
    }

    .col_full {
        flex: 1 0 0%;
    }

    @media(max-width:991px) {

        .col_full,
        .con_auto {
            width: 50%;
        }
    }

    @media(max-width:767px) {



        &.rightOrder {
            .order-1 {
                order: 0;
            }

            .con_auto {
                margin-bottom: 0px
            }
        }

        .col_full {
            flex: 0 0 auto;
        }

        .con_auto {
            margin-bottom: 20px
        }

        .col_full,
        .con_auto {
            width: 100%;
        }

        .space_left {
            padding-left: 15px
        }
    }
}

