// our spaces page start
.our-spaces-page-container {
  // height: 100vh;
  // padding-top: 160px;
  padding-top: 25px;
  padding-bottom: 30px;

  .page-header-container {
    // width: 850px;
    color: #fff;
    padding: 0 15px;

    .heading {
      font-size: 3.5rem;
      color: #fff;
      font-family: var(--bs-font-main-title-family);
      text-transform: capitalize;
      margin-bottom: 90px;
    }

    .description {
      font-size: 1rem;
    }
  }

  .space-list-container {
    .slick-dots {
      bottom: -30px;

      li {
        button {
          width: 12px;
          height: 12px;
          border: 1px solid #fff;
          background: transparent;
          border-radius: 50%;
        }
      }

      .slick-active button {
        background: #fff;

        &:before {
          opacity: 0;
        }
      }
    }
  }
}

.space-row-container {
  height: 315px;
  // width: 550px;
  cursor: pointer;

  .thumbnail-container,
  .details-container {
    height: 100%;
  }

  .thumbnail-container {
    border-radius: 20px;
    overflow: hidden;

    .thumbnail {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .details-container {
    display: flex;
    flex-direction: column;

    color: #fff;
    padding-left: 20px;

    .space-name {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      overflow: hidden;
      text-overflow: ellipsis;

      color: #fff;
      font-size: 1.6rem;
      font-family: var(--bs-font-main-title-family);
      padding-left: 20px;
      padding-top: 15px;
      margin-bottom: 10px;
    }

    .space-details-container {
      flex: 1;
      margin-bottom: 20px;

      .heading {
        font-size: 1rem;
        margin-bottom: 5px;

        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .content {
        font-size: 0.8rem;

        .key-info-option {
          margin-bottom: 5px;

          .icon-img {
            height: 1.15rem;
            width: 100%;
            object-fit: contain;

            filter: invert(100%);
          }

          .name {
            padding-left: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .price-tag {
          font-size: 0.75rem;
          text-transform: capitalize;

          margin-bottom: 0.25rem;
          margin-right: 0.25rem;
        }
      }
    }
  }

  .read-more {
    span {
      text-transform: uppercase;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
    }

    i {
      margin-left: 8px;
    }
  }

  .overlay-details-container {
    background: rgba(0, 0, 0, 0.5);
    color: #fff;

    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    text-align: center;

    .space-name {
      font-family: var(--bs-font-main-title-family);
      text-transform: capitalize;
    }

    .read-more {
      font-size: 0.875rem;
    }
  }
}

.space-row-container:hover {
  .read-more {
    i {
      img {
        animation: headShake 3s linear infinite;
        -webkit-animation: headShake 3s linear infinite;
      }
    }
  }
}

/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
  // our spaces list page container start
  .our-spaces-page-container {
    // padding-top: 120px;

    .page-header-container {
      width: 100%;
      padding: 0;

      .heading {
        font-size: 1.25rem;
        margin-bottom: 20px;
      }

      .description {
        font-size: 0.875rem;
      }
    }

    .space-list-slider {
      display: none;
    }
  }
  // our spaces list page container end

  // space row item start
  .space-row-container {
    height: calc(70vw);
    // width: calc(70vw);

    .details-container {
      display: none;
    }

    .overlay-details-container {
      display: flex;

      .space-name {
        font-size: 1.5rem;
        margin-bottom: 5px;
      }

      .read-more {
        font-size: 0.75rem;
      }
    }
  }
  // space row item end
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (min-width: 576px) {
  // our spaces list page container start
  .our-spaces-page-container {
    .page-header-container {
      .heading {
        font-size: 1.875rem;
        margin-bottom: 50px;
      }

      .description {
        font-size: 0.875rem;
      }
    }

    .space-list-slider {
      display: none;
    }
  }
  // our spaces list page container end

  // space row item start
  .space-row-container {
    height: 345px;
    // width: 345px;

    .details-container {
      display: none;
    }

    .overlay-details-container {
      display: flex;

      .space-name {
        font-size: 1.5rem;
        margin-bottom: 25px;
      }

      .read-more {
        font-size: 0.75rem;
      }
    }
  }
  // space row item end
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  // our spaces list page container start
  .our-spaces-page-container {
    .page-header-container {
      .heading {
        font-size: 2.25rem;
        margin-bottom: 50px;
      }

      .description {
        font-size: 1rem;
      }
    }

    .space-list-slider {
      display: block;

      .slide-content-container {
        width: 720px;
        margin: 0 15px;
      }
    }

    .one-space-list-slider {
      display: none;
    }
  }
  // our spaces list page container end
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  // our spaces list page container start
  .our-spaces-page-container {
    .page-header-container {
      .heading {
        font-size: 3rem;
        margin-bottom: 70px;
      }

      // .description {
      //   font-size: 1rem;
      // }
    }

    .space-list-slider {
      .slide-content-container {
        width: 955px;
        margin: 0 15px;
      }
    }
  }
  // our spaces list page container end

  // space row item start
  .space-row-container {
    height: 315px;
    // width: 470px;

    .thumbnail-container,
    .details-container {
      display: flex;
      // flex: 0 0 50%;
      // max-width: 50%;
    }

    .thumbnail-container {
      flex: 0 0 54.166%;
      max-width: 54.166%;
    }

    .details-container {
      flex: 0 0 45.834%;
      max-width: 45.834%;
    }

    .overlay-details-container {
      display: none;
    }
  }
  // space row item end
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  // our spaces list page container start
  .our-spaces-page-container {
    .page-header-container {
      .heading {
        font-size: 3.5rem;
        margin-bottom: 90px;
      }

      .description {
        padding: 0 70px;
      }
    }

    .space-list-slider {
      .slide-content-container {
        width: 1250px;
        margin: 0 50px;
      }
    }
  }
  // our spaces list page container end

  // space row item start
  .space-row-container {
    height: 315px;
    // width: 550px;
  }
  // space row item end
}

// our spaces page end

// our space details page start
.our-space-details-page-container {
  padding-bottom: 25px !important;
  color: #fff;

  .collapse-space-details-container {
    .expand-icon {
      color: #fff;
    }

    .ant-collapse-header {
      color: #fff;
      border: 1px solid #fff;
      margin-right: 50px;
      margin-bottom: 15px;
    }

    .ant-collapse-content {
      color: #fff;

      .ant-collapse-content-box {
        padding: 12px 0;
      }
    }
  }

  .space-name {
    font-family: var(--bs-font-main-title-family);
    text-transform: capitalize;

    margin-bottom: 15px;
  }

  .description-container {
    padding-right: 100px;
    font-size: 1rem;
  }

  .price-details {
    margin-bottom: 20px;

    .heading {
      font-size: 1.25rem;
    }

    .label,
    .value {
      font-size: 1rem;

      .vat {
        font-size: 9px;
      }
    }
  }

  .space-details-container {
    .key-information-container {
      .heading {
        font-size: 1.125rem;
        margin-bottom: 10px;
      }

      .key-info-row-item {
        // margin-bottom: 5px;
        margin-left: 10px;

        .icon-img {
          height: 1.15rem;
          width: 100%;
          object-fit: contain;

          filter: invert(100%);
        }
      }
    }
  }

  .collapse-space-details-container {
    .overview-panel {
    }

    .key-information-panel {
      .heading {
        font-size: 1.5rem;
        font-family: var(--bs-font-main-title-family);

        margin-bottom: 40px;
      }

      .key-infos-list {
        // padding-left: 15px;
        padding-left: 10px;

        .key-info-row-item {
          margin-bottom: 5px;
          // margin-left: 10px;

          .icon-img {
            height: 1.2rem;
            width: 100%;
            object-fit: contain;

            filter: invert(100%);
          }

          .name {
            padding-left: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .gallery-container {
    margin-top: 25px;
    margin-bottom: 40px;

    .gallery-image-container {
      height: 260px;

      .gallery-image {
        overflow: hidden;
        border-radius: 10px;
        object-fit: cover;
      }
    }
  }

  .floating-footer {
    background: #000;

    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;

    padding: 15px;

    .backPage {
      position: relative;
      left: 15px;
    }
  }

  .backPage {
    left: 30px;
  }
}

/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
  .our-space-details-page-container {
    padding: 0 15px;
    padding-bottom: 75px !important;

    .space-name {
      font-size: 1.5rem;
    }

    .space-details-container {
      display: none;
    }

    .collapse-space-details-container {
      display: block;

      .overview-panel {
        .price-details {
          .heading {
            font-size: 1rem;
          }

          .label,
          .value {
            font-size: 0.875rem;

            .vat {
              font-size: 9px;
            }
          }
        }
      }
    }

    .gallery-container {
      .gallery-image-container {
        height: calc(50vw - 22.5px);
      }
    }

    .floating-footer {
      display: block;
    }

    .footer {
      display: none;
    }
  }
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (min-width: 576px) {
  .our-space-details-page-container {
    padding: 0 15px;
    padding-bottom: 75px !important;

    .space-name {
      font-size: 1.875rem;
    }

    .space-details-container {
      display: none;
    }

    .collapse-space-details-container {
      display: block;
    }

    .gallery-container {
      .gallery-image-container {
        height: 165px;
      }
    }

    .floating-footer {
      display: block;
    }

    .footer {
      display: none;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .our-space-details-page-container {
    .space-name {
      font-size: 2.25rem;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .our-space-details-page-container {
    padding: 0 150px;
    padding-top: 25px;

    .space-name {
      font-size: 3rem;
    }

    .space-details-container {
      .description-container {
        padding-right: 50px;
      }
    }

    .floating-footer,
    .collapse-space-details-container {
      display: none;
    }

    .footer,
    .space-details-container {
      display: block;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .our-space-details-page-container {
    .space-details-container {
      .description-container {
        padding-right: 100px;
      }
    }

    .gallery-container {
      .gallery-image-container {
        height: 260px;
      }
    }
  }
}

/* Extra extra large devices (extra large desktops, 1600px and up) */
@media only screen and (min-width: 1600px) {
  .our-space-details-page-container {
    .space-details-container {
      .description-container {
        padding-right: 100px;
      }
    }
  }
}

// our space details page end
